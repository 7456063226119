import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      name: "登入頁",
      path: "/",
      component: () => import("@/views/login/Login"),
    },
    {
      name: "",
      path: "/manager",
      component: () => import("@/views/layout/Layout"),
      meta: { requiresAuth: true },
      children: [{
        name: "首頁",
        path: "home",
        component: () => import("@/views/home/Home"),
        meta: { requiresAuth: true },
      },
      

      /////系統管理//////////////////////////////////////////////////////
      {
        path: "organization",
        name: "系統管理,組織管理",
        component: () => import("@/views/systemManage/Department"),
        meta: { requiresAuth: true },
      },
      {
        path: "uof",
        name: "系統管理,UOF組織同步",
        component: () => import("@/views/systemManage/DepartmentUof"),
        meta: { requiresAuth: true },
      },
      {
        path: "menu",
        name: "系統管理,選單管理",
        component: () => import("@/views/systemManage/Menu"),
        meta: { requiresAuth: true },
      },
      {
        path: "features",
        name: "系統管理,功能管理",
        component: () => import("@/views/systemManage/Features"),
        meta: { requiresAuth: true },
      },
      {
        path: "role",
        name: "系統管理,角色管理",
        component: () => import("@/views/systemManage/Role"),
        meta: { requiresAuth: true },
      },

      /////Wafer//////////////////////////////////////////////////////
      {
        path: "inventoryMaintenance",
        name: "Wafer,庫存資料查詢",
        component: () => import("@/views/InventoryMaintenance/InventoryMaintenance"),
        meta: { requiresAuth: true },
      },
      {
        path: "bookingData",
        name: "Wafer,待 Booking資料",
        component: () => import("@/views/InventoryMaintenance/BookingData"),
        meta: { requiresAuth: true },
      },
      {
        path: "bookingWaferData",
        name: "Wafer,待 Booking Wafer 資料",
        component: () => import("@/views/InventoryMaintenance/BookingWaferData"),
        meta: { requiresAuth: true },
      },

      ///WMS//////////////////////////////////////////////////////
      {
        path: "receivingOperation",
        name: "WMS,收料作業",
        component: () => import("@/views/WMS/ReceivingOperation"),
        meta: { requiresAuth: true },
      },
      {
        path: "shelfOperation",
        name: "WMS,上架作業",
        component: () => import("@/views/WMS/ShelfOperation"),
        meta: { requiresAuth: true },
      },
      ]
    },
    {
      path: "*",
      redirect: "/",
    },
  ]
});