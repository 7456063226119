export default {
    install(Vue) {
      Vue.prototype.get_employee = function() {
        const api = `${process.env.VUE_APP_API}/auth/backgrounduser`;
  
        const params = {
            withDept: false,
          };  
          return new Promise((resolve, reject) => {
            this.$http.get(api,{params}).then(
              (response) => {
                let dateList = [...response.data];
                dateList.sort(function (a, b) {
                    return a.Account > b.Account ? 1 : -1;
                  }); 

                resolve(dateList);
              },
              (error) => {
                reject(error);
              }
            );
          });
      };
    },
  };
  