import Vue from 'vue'
import App from './App.vue'
import router from "@/router/router";

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import jquery from "jquery";
import "bootstrap";


import VueBootstrap4Table from "vue-bootstrap4-table";
Vue.component("VueBootstrap4Table", VueBootstrap4Table);

import VueTableDynamic from 'vue-table-dynamic'
Vue.use(VueTableDynamic);

import Multiselect from 'vue-multiselect';
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component('multiselect', Multiselect)


import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

import EvaIcons from 'vue-eva-icons'
Vue.use(EvaIcons)


//element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import lang from "element-ui/lib/locale/lang/zh-TW";
import locale from "element-ui/lib/locale";
locale.use(lang);

//el-table拖拉
import ElTableDraggable from 'element-ui-el-table-draggable';
Vue.component('elTableDraggable', ElTableDraggable)


//vue2-datepicker
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
Vue.component("DatePicker", DatePicker);

//vue-tree-list
import VueTreeList from 'vue-tree-list'
Vue.use(VueTreeList)

//@reallife/vue-tree-grid
import VueTreeGrid from "@reallife/vue-tree-grid";
Vue.use(VueTreeGrid);

//@riophae/vue-treeselect
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
Vue.use(Treeselect);
Vue.component("Treeselect", Treeselect);


// AOS
import AOS from "aos";
import "aos/dist/aos.css";
Vue.use(AOS);


// cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

//權限
// Vue.prototype.$Permission = VueCookies; 權限cookie放不下 改成下列寫法
Vue.prototype.$Permission = JSON.parse(localStorage.getItem('Permission'));


// Loading
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.component("Loading", Loading);

//fortawesome
import "@fortawesome/fontawesome-free/js/all";


import VueI18n from "vue-i18n"; // 引入 Vue I18n
import zhTW from "./i18n/zh"; // 存放中文語系檔
import en from "./i18n/en"; // 存放英文語系檔

Vue.use(VueI18n);
// 預設使用的語系
let language = "zh-TW";

// 檢查 sessionStorage 是否已有保存使用者選用的語系資訊
// if (sessionStorage.getItem("footmark-lang")) {
//   language = sessionStorage.getItem("footmark-lang");
//   store.commit("setLang", language);
// } else {
//   store.commit("setLang", language);
// }


const i18n = new VueI18n({
  locale: language,
  messages: {
    "zh-TW": zhTW,
    en: en,
  },
});

// vee-validate
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
extend("integer", integer);
extend("excluded", excluded);
extend("required", required);
extend("numeric", numeric);
extend("alpha_dash", alpha_dash);
extend("alpha_num", alpha_num);
extend("email", email);
extend("confirmed", confirmed);
extend("regex", regex);
extend("my_not", {
  validate: (value, { now }) => {
    return value !== now;
  },
  message: "{_field_} 不可重複",
  params: ["now"],
});
extend("password_length", {
  validate: (value, args) => {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "{_field_} 長度不符合",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "新密碼輸入不符",
});


extend("min_value", {
  ...min_value,
  message: TW.messages.min_value,
  params: ["min"],
});

import {
  required,
  numeric,
  alpha_dash,
  alpha_num,
  excluded,
  email,
  min_value,
  confirmed,
  integer,
  regex,
} from "vee-validate/dist/rules";

import TW from "vee-validate/dist/locale/zh_TW.json";
localize("zh_TW", TW);

import store from "./store";
import "./bus";

window.$ = jquery;
// Vue.use(Multiselect);


Vue.config.productionTip = false


axios.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json; charset=utf-8";
  return config;
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')



// router.beforeEach((to, from, next) => {
//   // 如果需要驗證
//   if (to.meta.requiresAuth) {
//     if (document.cookie === "") {
//       // 轉跳到 homePage
//       next({ path: "/" });
//     } else {
//       next(); // 往下繼續執行
//     }
//   } else {
//     next();
//   }
// });




router.beforeEach((to, from, next) => {
  Vue.prototype.$Permission = JSON.parse(localStorage.getItem('Permission'));
  // 後台
  if (to.meta.requiresAuth) {
    // next();
    // 如果 cookie 裡面有 Token
    //if (VueCookies.get("userData")) {太長存不進去cookie改成下列寫法
    if (localStorage.getItem("token")) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    // 燈入頁面
    next();
  }
});




////////////////// 共用方法 ///////////////////////////////////////

import get_employee from "@/assets/js/select/employee.js";
Vue.use(get_employee);


