import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    
    // API 回應資訊
    showResponseData: false,
    // 多國語系
    lang: null,
    isLoading: false,    
    editMenu: false, // 選單管理變更
  },
  actions: {
    updateLoading(context, status) {
      context.commit("LOADING", status);
    },
  },

  mutations: {
    
    // API 回應資訊
    OpenResponseDataModal(state, payload) {
      state.showResponseData = payload;
    },

    SELECT_LANGUAGE(state, payload) {
      state.lang = payload;
    },
    LOADING(state, status) {
      state.isLoading = status;
    },
     // 選單管理
     EDITMENU(state, payload) {
      state.editMenu = payload;
    },
  },  
  
  getters: {
    
    showResponseData: (state) => state.showResponseData, // API 回應資訊 Modal
    lang: (state) => state.lang, // 多國語系
    editMenu: (state) => state.editMenu, // 選單管理變更
  },
});